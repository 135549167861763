var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"0afa1336e0f51526d8218c37970b2ee97738d00c"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { isLocalhost } from '@/configs/config';
import * as Sentry from '@sentry/nextjs';
// const { version } = require('./package.json');

const SENTRY_DSN = 'https://dc6b2a85cbe02dbd21962ea700c824a6@o4508364143460352.ingest.us.sentry.io/4508364146343936'

Sentry.init({
  dsn: SENTRY_DSN,
  beforeSend(event, hint) {
    // Access the original exception
    const axiosError = hint.originalException;
    
    const errorMessage = axiosError?.response?.data?.error

    if (errorMessage) {
      event.exception.values[0].type = errorMessage; 
    }

    return event;
  },
  ignoreErrors: [/^please login using your existing phone/, /^please login using your existing email/],
  release:
    process.env.VERCEL_GIT_COMMIT_SHA ||
    process.env.NEXT_PUBLLIC_VERCEL_GIT_COMMIT_SHA,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: isLocalhost ? 0.0 : 0.4,
  environment:
    process.env.NEXT_PUBLIC_ENV === 'production' ? 'production' : 'development',
  enabled: isLocalhost ? false : true,
  replaysOnErrorSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  integrations: [
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
